<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-07-07 11:35:33
 * @LastEditors: ------
 * @LastEditTime: 2021-07-08 09:34:08
-->
<template>
  <div class="">
    <a-select
      show-search
      v-model="valueFind"
      placeholder="input search text"
      style="width: 200px"
      :default-active-first-option="false"
      :show-arrow="false"
      :filter-option="false"
      :not-found-content="null"
      @search="handleSearch"
      @change="handleChange"
    >
      <a-select-option v-for="(d, index) in data" :key="index">
        {{ d.text }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { getNurseFind } from "../api/api";
// import _ from "lodash";

export default {
  name: "",
  data() {
    return {
      data: [],
      valueFind: undefined,
    };
  },
  created() {},
  components: {},
  props: {},
  computed: {},
  methods: {
    handleSearch(valueFind) {
      fetch(valueFind, (data) => (this.data = data));
    },
    handleChange(valueFind) {
      console.log(valueFind);
    },
  },
};

let timeout;

function fetch(valueFind, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  function fake() {
    if (valueFind == "") {
      return false;
    } else {
      getNurseFind({ key: valueFind, volunteer: false }).then((res) => {
        var result = res.data.result;
        if (res.data.success) {
          const data = [];
          result.forEach((r) => {
            data.push({
              value: r.id,
              text: r.name + "-" + r.mobile,
              phone: r.mobile,
            });
          });
          callback(data);
        }
      });
    }
  }

  timeout = setTimeout(fake, 500);
}
</script>
<style scoped></style>
